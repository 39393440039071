<template>
  <div class="pt-10">
    <button
      :disabled="timerEnabled"
      type="button"
      class="btn btn-primary bg-blue-400 btn-block"
      @click="resendActivation"
    >
      {{ $t('loginRegister.resendAct') }}
      {{ timerEnabled ? `: (${countDown})` : '' }}
    </button>
  </div>
</template>

<script>
export default {
  name: 'resend-activation',
  props: {
    email: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      timerEnabled: false,
      countDown: 3,
    };
  },
  methods: {
    countDownTimer() {
      if (this.countDown > 0) {
        setTimeout(() => {
          this.countDown -= 1;
          this.countDownTimer();
        }, 1000);
      } else {
        this.timerEnabled = false;
      }
    },
    resendActivation() {
      try {
        this.timerEnabled = true;
        this.countDown = 120;
        this.countDownTimer();
        this.$store.dispatch('v2/auth/resendActivation', this.email);
        // eslint-disable-next-line no-empty
      } catch (e) {}
    },
  },
};
</script>

<style scoped></style>
